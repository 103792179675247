import React from "react";

export default function Herosection() {
  return (
    <div>
      <div className="herosection">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 align-self-center text-center">
              <h1>Launching Soon..</h1>
              <h2>A Better Way of Life</h2>
              <p>
                Your heart is where <b> NCC Urban Lake Springs,</b> JP Nagar 9th
                phase is located. In keeping with its moniker, the project
                offers the start of an unparalleled way of life on the most
                tranquil piece of land in the city. Specially with a gorgeous
                view of the lake. The new standard in super high-rise living is
                <b> NCC Urban Lake Springs</b>, which offers elegant towers with
                more than 16 stories in addition to <b>vastu compliant </b>
                homes with <b> 2, 3 & 3.5 bedrooms.</b> Additionally, it
                provides carefully chosen amenities for a refined way of living.
              </p>
              <button
                type="button"
                class="btn mt-2"
                data-toggle="modal"
                data-target="#exampleModal"
              >
                Enquire Now
              </button>

              <div
                class="modal fade text-left"
                id="exampleModal"
                tabindex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div
                  class="modal-dialog modal-dialog-centered  modal-lg"
                  role="document"
                >
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title" id="exampleModalLabel">
                        Contact form
                      </h5>
                      <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      <form
                        name="contact"
                        method="post"
                        data-netlify="true"
                        onSubmit="submit"
                        data-netlify-honeypot="bot-field"
                      >
                        <input type="hidden" name="form-name" value="contact" />
                        <div class="row">
                          <div class="col">
                            <input
                              required
                              type="text"
                              class="form-control"
                              placeholder="Name"
                              name="name"
                            />
                          </div>
                          <div class="col">
                            <input
                              required
                              type="text"
                              class="form-control"
                              placeholder="Phone Number"
                              name="phonenumber"
                            />
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div class="col">
                            <input
                              required
                              type="text"
                              class="form-control"
                              placeholder="Email"
                              name="email"
                            />
                          </div>
                          <div class="col">
                            <fieldset>
                              <div class="some-class">
                                <label>Need Brochure</label>{" "}
                                <br class="mobile-break"></br>
                                <input
                                  type="radio"
                                  class=" ml-0 ml-md-3 mr-1"
                                  name="brochure"
                                  value="Yes"
                                />
                                <label for="y">Yes</label>
                                <input
                                  type="radio"
                                  class="radio ml-3 mr-1"
                                  name="brochure"
                                  value="No"
                                />
                                <label for="no">No</label>
                              </div>
                            </fieldset>
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div class="col">
                            <input
                              type="text"
                              required
                              class="form-control"
                              placeholder="Message"
                              name="message"
                            />
                          </div>
                        </div>
                        {/* 
                        <div className="row">
                          <div className="col-12">
                            <div
                              style={{ fontSize: 12 }}
                              className="text-center"
                            >
                              *Phase - 1 Sold Out,{" "}
                              <b>Hurry up for Phase - 2 (NEW LAUNCH)</b>
                            </div>
                          </div>
                        </div> */}

                        <div className="row">
                          <div className="col-12 text-center">
                            <button
                              type="submit"
                              className="btn btn-primary mt-4"
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 text-right">
              <img className="img-fluid" src="./images/banner_img.jpg" alt="" />
              <small>*for illustration purpose only</small>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="disclimer">
                Disclaimer The Project is being Developed by NCC Urban Lake
                Springs. The Project is registered as "NCC Urban" with ACK. RERA
                No:ACK/KA/RERA/1251/310/PR/190724/008341 , available at
                website:http://rera.karnataka.gov.in. The information is
                presented as general information and no warranty is expressly or
                impliedly given that the completed development will comply in
                any degree with such artist’s impression or anticipated
                appearance.Recipients are advised to apprise themselves of the
                necessary and relevant information of the project(s)/offer(s)
                prior to making any purchase decisions. The Sale is subject to
                terms of Application Form and Agreement for Sale. All
                specifications of the unit shall be as per the final agreement
                between the Parties. *The prices mentioned are an indicative
                Agreement Value. Stamp Duty Registration, GST and Other Charges
                over and above the Agreement Value. The amenities mentioned here
                are a part of NCC Urban and proposed future development. This is
                an initiative by Smita an Individual Channel Partner RERA Regn
                No. PRM/KA/RERA/1251/310/AG/220521/002906 to provide information
                about NCC Urban is not to be construed as an official site of
                NCC Urban. Distance mentioned is as per google map. Drive time
                refers to the time taken to travel by a car based on normal
                traffic conditions during non-peak hours as per Google maps may
                vary according to the current traffic conditions. Information
                provided is based on Online sources, the developer does not
                provide any guarantee on the same.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
